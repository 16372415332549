import React from "react"
import styled from "styled-components"

const HeadingStyles = styled.div`
  padding-top: 60px;
  background-color: #c5cacf;
  #content-container {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 1000px) {
      width: 100%;
      font-size: smaller;
    }
    #heading {
      display: flex;
      justify-content: center;
      h1 {
        margin: 0rem 0;
        font-size: 2.5em;
        padding: .5rem 0rem;
        font-weight: bold;
        text-align: center;

        @media (max-width: 1070px) {
          font-size: 2em;
        }

        @media (max-width: 525px) {
          font-size: 1.5em;
        }

        @media (max-width: 400px) {
          font-size: 1.2em;
          font-weight: bold;
        }
      }
      h2 {
        font-size: 2.5em;
        text-align: center;
        margin: 0.5rem 0;

        @media (max-width: 1070px) {
          font-size: 2em;
        }
        @media (max-width: 525px) {
          font-size: 1.5em;
        }

        @media (max-width: 400px) {
          font-size: 1.2em;
          font-weight: bold;
        }
      }
    }

    p {
      padding: 1rem;
      text-align: center;
      margin: 0;
    }
  }
`

const PageHeadings = props => {
  return (
    <HeadingStyles>
      <div id="content-container">
        <div id="heading">
          <h2>{props.subHeading} </h2>
          <h1>{props.title}</h1>
        </div>
      </div>
    </HeadingStyles>
  )
}

export default PageHeadings
