import React from "react"
import styled from "styled-components"
import HistoryImage from "../images/RPS-Front-Edit.jpg"

const HistoryStyles = styled.div`
  #history-container {
    position: relative;
    /* padding: 1rem 0; */
    /* height: 200px; */
    color: white;
    font-size: 1.2em;
    padding-top: 2rem;

    @media (max-width: 1000px) {
      font-size: 1em;
    }

    @media (max-width: 700px) {
      font-size: 0.8em;
    }

    @media (max-width: 500px) {
      font-size: 1em;
    }

    #image-container {
      position: relative;
      height: 600px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 500px) {
        height: 1000px;
      }
    }

    #text-container {
      position: absolute;
      /* width: 2000px; */
      margin: auto;
      /* width: 80%; */
      top: 5rem;
      /* left: 50%; */
      /* margin-left: -761.2px; */
      z-index: 2;

      h1 {
        margin: 0;
        padding: 20px 0 0 40px;
      }

      p {
        padding: 2rem;
        width: 80%;
        margin-right: auto;
        margin-left: auto;

        @media (max-width: 1120px) {
          width: 90%;
        }
      }
    }

    #overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
    }
  }
`

const HistorySection = () => {
  return (
    <HistoryStyles>
      <div id="history-container">
        <div id="image-container">
          <img src={HistoryImage} alt="" />
        </div>
        <div id="overlay"></div>
        <div id="text-container">
          <h1 style={{ textAlign: "center" }}>About Us</h1>
          <p>
            Timberline designs and builds its commercial cleaning equipment
            proudly in Wisconsin, USA. Our company is owned and managed by the
            founding family with roots back to the 1980’s. Our Timberline
            equipment is simple and durable, targeting customers who are mindful
            of their floor cleaning budget.
            <br />
            <br />
            In addition to designing our machine from scratch we also produce
            many of the components in-house and invest heavily in finished
            sub-assemblies. This allows us to build your custom machine order in
            a matter of hours and ship in just a few days. Floor cleaning
            machines built to your application’s needs without long delays or
            irregularities from small “custom” shops.
            <br />
            <br />
            Timberline Floor Scrubbers is a lower-priced, quality alternative to
            cheap imported products or reconditioned / demo equipment.
            Timberline is sold and serviced through Local Distributors
            world-wide. By controlling the design and assembly process 100%, we
            can ship necessary parts or provide technical support the very same
            day.
            <br />
            <br />
            At Timberline, we strive to be better and different than the rest
            and put our passion into our equipment. We hope you find our
            equipment meets or exceeds all of your expectations.
          </p>
        </div>
      </div>
    </HistoryStyles>
  )
}

export default HistorySection
