import React from "react"
import { Link } from "gatsby"
import MainLayout from "../components/MainLayout"
import PageHeadings from "../components/PageHeadings"
import HistorySection from "../components/HistorySection"
import styled from "styled-components"
import IndexSection3 from "../components/IndexSection3"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

const ContactStyles = styled.div`
  #contact-section {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`

const AboutPage = () => {
  return (
    <>
      <SEO title="About Us - Our Location and History | Timberline Cleaning Equipment" />
      <Helmet>
        <title>
          About Us - Our Location and History | Timberline Cleaning Equipment
        </title>
        <description>
          We are located at: 1711 South Street, Racine WI 53404
        </description>
      </Helmet>
      <div>
        <MainLayout>
          <HistorySection />
          <ContactStyles>
            {/* <div id="contact-section">
            <div id="left-container">
              <h2>Contact Information</h2>
            </div>
            <div id="right-container">
              <h2>Contact Us</h2>
            </div>
          </div> */}
          </ContactStyles>
          <a
            href="https://www.google.com/maps?ll=42.758149,-87.802912&z=15&t=m&hl=en&gl=US&mapclient=embed&q=1711+South+St+Racine,+WI+53404"
            target="_blank"
          >
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2929.428180666852!2d-87.80510068414654!3d42.758148979162165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880543dbb04d00e7%3A0x4bd6f3adf71e7dd5!2s1711%20South%20St%2C%20Racine%2C%20WI%2053404!5e0!3m2!1sen!2sus!4v1579100275527!5m2!1sen!2sus"
                style={{ width: "100%", height: "15rem" }}
              ></iframe>
            </div>
          </a>
          <IndexSection3 title="Contact Us" />
        </MainLayout>
      </div>
    </>
  )
}

export default AboutPage
